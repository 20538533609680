<template>
  <div class="rules-conditions-relationship">
    <el-form-item
      prop="property"
      :rules="[{ required: true, validator: validateProperty_name, trigger: 'change' }]"
    >
      <el-select v-model="property" placeholder="请选择" value-key="name">
        <el-option
          v-for="opt in property_name_options"
          :key="opt.name"
          :label="opt.memo"
          :value="opt"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      prop="property_type"
      :rules="[{ required: true, validator: validateCondition_relation_type, trigger: 'change' }]"
    >
      <el-select v-model="condition.condition_relation_type" placeholder="请选择">
        <el-option
          v-for="opt in condition_relation_type_options"
          :key="opt.value"
          :label="opt.label"
          :value="opt.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      prop="condition_relation_type"
      :rules="[
        {
          required: true,
          validator: validateCondition_value,
          trigger: property.element_type === 'SELECT' ? 'change' : 'blur',
        },
      ]"
    >
      <el-select
        v-if="property.element_type === 'SELECT'"
        v-model="condition.condition_value"
        multiple
        collapse-tags
        placeholder="请选择"
      >
        <el-option
          v-for="opt in condition_value_options"
          :key="opt.id"
          :label="opt.label"
          :value="opt.id"
        >
        </el-option>
      </el-select>
      <el-input v-else v-model="condition.condition_value" placeholder="请选择"></el-input>
    </el-form-item>

    <div class="del" v-if="isDel" @click="delCondition(condition.id)">
      <el-tooltip effect="dark" content="删除该条规则" placement="top">
        <i class="el-icon-delete"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "RulesConditions",
  props: {
    condition: {
      type: Object,
      default() {
        return {
          parent_id: "root",
          id: `c-${new Date().getTime() + random(1, 100)}`,
          property_name: "",
          property_type: "",
          condition_value: "",
          condition_relation_type: "",
        };
      },
    },
  },
  data() {
    return {
      validateProperty_name: (rule, value, callback) => {
        if (!this.condition.property_name) {
          return callback(new Error("规则1不能为空"));
        }
        callback();
      },
      validateCondition_relation_type: (rule, value, callback) => {
        if (!this.condition.condition_relation_type) {
          return callback(new Error("规则2不能为空"));
        }
        callback();
      },
      validateCondition_value: (rule, value, callback) => {
        if (!this.condition.condition_value) {
          return callback(new Error("规则3不能为空"));
        }
        callback();
      },
      property: {},
      condition_value_options: [],
    };
  },
  computed: {
    ...mapGetters(["property_name_options", "condition_relation_type_options", "conditions"]),
    isDel() {
      return (
        this.conditions.filter((item) => {
          return item.parent_id === this.condition.parent_id;
        }).length > 1
      );
    },
  },
  watch: {
    property: {
      handler(val, oldVal) {
        const _this = this;
        if (!val || Object.keys(val).length === 0) return;
        _this.condition.property_name = val.name;
        _this.condition.property_type = val.property_type;
        _this.condition_value_options = val.element_values;
        if (oldVal && val && oldVal.name && val.name && val.name !== oldVal.name) {
          _this.condition.condition_value = val.element_type === "SELECT" ? [] : "";
        }
      },
    },
    "condition.property_name": {
      handler(val, oldVal) {
        if (val === "") {
          this.property = {};
        }
        console.log("condition.property_name_val", val);
        console.log("condition.property_name_oldVal", oldVal);
      },
    },
    property_name_options: {
      handler(val) {
        if (!val || val.length === 0) return (this.property = {});
        this.property = val.find((opt) => opt.name === this.condition.property_name) || {};
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setConditions", "setConditionGroups", "delConditions"]),
    delCondition(id) {
      this.delConditions(id);
    },
  },
  mounted() {
    console.log("mounted");
    if (this.condition.property_name == "") {
      this.property = {};
    }
  },
};
</script>

<style lang="less">
.rules-conditions-relationship {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 30px;
  // padding-left: 20px;
  box-sizing: border-box;
  & > .del {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    right: -10px;
    font-size: 18px;
    cursor: pointer;
  }
  .el-select {
    margin-right: 10px;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
</style>
