<template>
  <el-dialog title="规则" :visible.sync="dialogVisible" width="1200px">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="规则名称" prop="name" :label-width="formLabelWidth">
        <div style="width: 400px">
          <el-input v-model="form.name" placeholder="请输入规则名称"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="条件规则" prop="condition_group" :label-width="formLabelWidth" required>
        <div class="rule-edit">
          <TqRuleGeneration :condition-group="conditionGroupTree"> </TqRuleGeneration>
        </div>
      </el-form-item>
      <el-form-item label="分配对象" prop="return_value.members" :label-width="formLabelWidth">
        <div style="width: 400px">
          <el-tooltip effect="dark" content="组织树暂未完成，先使用下拉框！" placement="top-end">
            <el-select
              v-model="form.return_value.members"
              filterable
              multiple
              placeholder="添加人员"
            >
              <el-option
                v-for="opt in userList"
                :key="opt.id"
                :label="opt.name"
                :value="opt.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="分配方式" prop="return_value.assign_type" :label-width="formLabelWidth">
        <el-radio label="ROUND_ROBIN" v-model="form.return_value.assign_type"
          >按顺序轮流分配</el-radio
        >
      </el-form-item>
      <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
        <div style="width: 400px">
          <el-input v-model="form.memo" type="textarea" placeholder="请输入活动备注"></el-input>
        </div>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { getUserTree } from "@/api/common";
import { getRuleDetail, createRule, putRule } from "@/api/rule";
import { mapGetters, mapMutations } from "vuex";
import TqRuleGeneration from "@/components/TqRuleGeneration/index.vue";
import { cloneDeep } from "lodash-es";
import { condition_valueToString } from "@/store/modules/conditionGroup";

export default {
  components: { TqRuleGeneration },
  props: {},
  data() {
    return {
      dialogVisible: false,
      id: "",
      formLabelWidth: "120px",
      form: {
        name: "",
        memo: "",
        condition_group: this.conditionGroupTree,
        rule_type: "LEAD_ASSIGN",
        return_value: {
          members: [],
          assign_type: "ROUND_ROBIN",
        },
      },
      userList: [],
      rules: {
        name: [{ required: true, message: "请输入专题名称", trigger: "blur" }],
        memo: [{ required: true, message: "请输入活动备注", trigger: "blur" }],
        "return_value.members": [{ required: true, message: "请选择分配对象", trigger: "change" }],
        "return_value.assign_type": [
          { required: true, message: "请选择分配方式", trigger: "change" },
        ],
        condition_group: [{ required: true, message: "请选择分配方式", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapGetters(["conditions", "conditionGroups", "conditionGroupTree"]),
  },
  watch: {
    conditionGroupTree: {
      handler(newVal) {
        this.form.condition_group = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const { id } = this.$route.query;
    this.rule_id = id;
    const { data } = await getUserTree();
    this.userList = data.filter((item) => item.id);
  },

  methods: {
    ...mapMutations(["resetCondition_groups", "treeRuleToList"]),
    initForm() {
      this.form = {
        name: "",
        memo: "",
        condition_group: this.conditionGroupTree,
        rule_type: "LEAD_ASSIGN",
        return_value: {
          members: [],
          assign_type: "ROUND_ROBIN",
        },
      };
    },
    handleEdit(v) {
      const _this = this;
      _this.id = v;

      _this.$store.dispatch("InitOptions", _this.form.rule_type).then(async () => {
        if (_this.id) {
          const res = await getRuleDetail(_this.id);
          _this.treeRuleToList(res.data.condition_group);
          _this.form = {
            name: res.data.name,
            memo: res.data.memo,
            condition_group: _this.conditionGroupTree,
            rule_type: res.data.rule_type,
            return_value: {
              members: res.data.return_value.members.map((item) => (item ? item.id : item)),
              assign_type: res.data.return_value.assign_type,
            },
          };

          _this.$refs["ruleForm"] && _this.$refs["ruleForm"].clearValidate();
          _this.dialogVisible = true;
        } else {
          _this.resetCondition_groups();
          _this.initForm();
          setTimeout(() => {
            _this.$refs["ruleForm"] && _this.$refs["ruleForm"].clearValidate();
          }, 200);

          _this.dialogVisible = true;
        }
      });
    },

    submit() {
      const _this = this;
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          const data = cloneDeep(this.form);
          data.condition_group = condition_valueToString(data.condition_group);
          data.return_value.members = data.return_value.members
            .map((item) => {
              return _this.userList.find((i) => i.id == item);
            })
            .filter((item) => item);
          if (this.id) {
            await putRule(this.id, data);
          } else {
            await createRule(data);
          }
          this.$parent.fetchData();
          this.dialogVisible = false;
          this.$parent.$message({
            message: this.id ? "修改活动信息成功" : "添加活动信息成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/form.less");
.rule-edit {
  width: 800px;
}
</style>
