<template>
  <div class="rules">
    <template v-if="conditionGroup.conditions && conditionGroup.conditions.length">
      <div
        class="rules-conditions"
        v-for="(item, index) in conditionGroup.conditions"
        :key="'conditions' + index"
      >
        <RulesConditions :condition="item"></RulesConditions>
      </div>
    </template>
    <template
      v-if="conditionGroup.child_condition_group && conditionGroup.child_condition_group.length"
    >
      <TqRuleGeneration
        v-for="(child_item, child_index) in conditionGroup.child_condition_group"
        :condition-group="child_item"
        :key="'child' + child_index"
        :bg-color="bgColor === '#ffffff' ? '#f7f7f7' : '#ffffff'"
      >
      </TqRuleGeneration>
    </template>
    <div class="rules-conditions rules-conditions-operation">
      <el-button
        plain
        type="primary"
        icon="el-icon-plus"
        @click="() => addCondition(conditionGroup.id)"
        >添加条件</el-button
      >
      <el-button
        plain
        type="primary"
        icon="el-icon-plus"
        @click="() => addConditionGroup(conditionGroup.id)"
        >添加条件组</el-button
      >
    </div>
    <div class="rules-line">
      <div
        class="rules-line-relationship"
        v-show="logic_type"
        @click="changeLogicType(conditionGroup.id)"
      >
        <el-tooltip effect="dark" content="切换条件逻辑" placement="top">
          <span>{{ conditionGroup.condition_logic_type === "AND" ? "且" : "或" }}</span>
        </el-tooltip>
      </div>
    </div>
    <div class="group_del" v-if="!isDel" @click="delConditionGroup(conditionGroup.id)">
      <i class="el-icon-close"></i>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import RulesConditions from "./RulesConditions.vue";
export default {
  name: "TqRuleGeneration",
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    ruleType: {
      type: String,
      default: "LEAD_ASSIGN",
    },
    conditionGroup: {
      type: Object,
      default() {
        return {
          conditions: [],
          child_condition_group: null,
          condition_logic_type: "AND",
        };
      },
    },
  },
  components: { RulesConditions },
  data() {
    return {
      lineWidth: "60px",
      lineLeft: "-60px",
    };
  },
  computed: {
    ...mapGetters([
      "property_name_options",
      "property_type_options",
      "condition_relation_type_map",
    ]),
    logic_type() {
      const conditionsLen =
        this.conditionGroup &&
        this.conditionGroup.conditions &&
        this.conditionGroup.conditions.length;
      const conditionGroupLen =
        this.conditionGroup.child_condition_group &&
        this.conditionGroup.child_condition_group.length;

      return conditionGroupLen || conditionsLen;
    },
    isDel() {
      return this.conditionGroup.id === "root";
    },
  },
  methods: {
    ...mapMutations(["setConditions", "setConditionGroups", "setLogicType", "delConditionGroups"]),
    addCondition(parent_id) {
      this.setConditions({
        parent_id: parent_id,
        property_name: "",
        property_type: "",
        condition_value: "",
        condition_relation_type: "",
      });
    },
    addConditionGroup(parent_id) {
      this.setConditionGroups({
        parent_id: parent_id,
        condition_logic_type: "AND",
        conditions: null,
        child_condition_group: null,
      });
    },
    changeLogicType(id) {
      this.setLogicType(id);
    },
    delConditionGroup(id) {
      this.delConditionGroups(id);
    },
  },
  created() {
    this.$store.dispatch("InitOptions", this.ruleType);
  },
};
</script>

<style lang="less">
.rules {
  margin-top: 20px;
  margin-left: 60px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: content-box;
  padding: 20px;
  background-color: v-bind(bgColor);
  z-index: 10;
  & > .rules {
    z-index: 11;
  }
  & > .rules-line {
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    position: absolute;
    top: 45px;
    left: 20px;
    border-radius: 10px 0 0 10px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border: dashed 1px #aeb0b5;
    border-right: 0;
    border-bottom: 0;
    & > .rules-line-relationship {
      width: 30px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      text-align: center;
      background-color: #eef0fa;
      border-radius: 2px;
      position: absolute;
      top: calc(33% - 10px);
      left: -15px;
      z-index: 20;
    }
  }
  & > .rules-conditions {
    width: calc(100% - 60px);
    height: 50px;
    margin-left: v-bind(lineWidth);
    // border: solid 1px #ccc;
    border-radius: 2px;
    background-color: v-bind(bgColor);
    margin-bottom: 25px;
    position: relative;
    z-index: 100;
    &::after {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      width: v-bind(lineWidth);
      height: 10px;
      border-bottom: dashed 1px #aeb0b5;
      position: absolute;
      top: calc(50% - 8px);
      left: v-bind(lineLeft);
      z-index: 9;
    }
  }

  & > .rules-conditions:first-of-type {
    border: salmon 1px red;
    &::after {
      display: none;
    }
  }
  & > .rules-conditions-operation {
    width: 150px;
    height: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    &::after {
      border-radius: 0 0 0 10px;
    }
  }
  .group_del {
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: -0px;
    right: 8px;
    z-index: 999;
  }
}
</style>
