<template>
  <div class="app-container">
    <el-card class="allocation-rules">
      <div slot="header" class="card-header">
        <span>分配规则列表</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          v-permission="['rule.add_lead_assign_rule']"
          @click="$refs.edit.handleEdit()"
          >创建规则</el-button
        >
      </div>

      <el-table
        row-key="id"
        v-loading.body="tableLoading"
        :data="tableData"
        element-loading-text="加载中 ..."
        border
        header-align="center"
        highlight-current-row
      >
        <el-table-column min-width="40px" align="center" header-align="center">
          <template>
            <i class="el-icon-s-operation dragged-icon"></i>
          </template>
        </el-table-column>

        <el-table-column
          label="规则名称"
          min-width="200"
          prop="name"
          align="center"
          header-align="center"
        />
        <el-table-column label="使用成员/部门" min-width="310" align="center">
          <template slot-scope="scope">
            {{ scope.row.return_value.members.map((item) => (item ? item.name : "")).join("/") }}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          min-width="200"
          prop="memo"
          align="center"
          header-align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          label="创建人"
          min-width="80"
          prop="creator.name"
          header-align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          label="创建时间"
          min-width="180"
          prop="create_time"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="修改人"
          min-width="80px"
          prop="updater.name"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="修改时间"
          min-width="180"
          prop="update_time"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="状态"
          min-width="80"
          prop="is_enable"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch :value="scope.row.is_enable" @change="changeSwitch(scope.row)"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="80px"
          align="center"
          header-align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-permission="['rule.modify_lead_assign_rule']"
              size="small"
              @click="$refs.edit.handleEdit(scope.row.id)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <EditRules ref="edit"></EditRules>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import EditRules from "./EditRules.vue";
import { getRuleList, moveRule, enableRule } from "@/api/rule";
export default {
  components: {
    EditRules,
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changeSwitch(row) {
      enableRule(row.id, { is_enable: !row.is_enable }).then(() => {
        this.fetchData();
      });
    },
    goEdit(row) {
      this.$router.push(`/generator/${row.uuid}`);
    },
    async fetchData() {
      const res = await getRuleList(this.queryParams);
      this.tableData = res.data;
      this.initSort();
    },
    timeFormatter(row) {
      return `${row.start_time} 至 ${row.end_time}`;
    },
    initSort() {
      const el = document.querySelectorAll(
        ".allocation-rules .el-table__body-wrapper > table > tbody"
      )[0];
      // 根据具体需求配置options配置项
      new Sortable(el, {
        handle: ".dragged-icon",
        animation: 150,
        onMove: (evt) => {
          console.log("onMove");
          console.log(evt); // 当前行的被拖拽前的顺序
          console.log(evt.oldIndex); // 当前行的被拖拽前的顺序
          console.log(evt.newIndex); // 当前行的被拖拽后的顺序
          if (evt.oldIndex) {
            return false;
          }
        },
        // 拖拽元素改变位置的时候
        onChange: function (evt) {
          console.log("onChange");
          console.log(evt.oldIndex);
          console.log(evt.newIndex);
        },
        onEnd: (evt) => {
          // 监听拖动结束事件
          console.log(evt.oldIndex); // 当前行的被拖拽前的顺序
          console.log(evt.newIndex); // 当前行的被拖拽后的顺序
          //从旧索引处删除元素，并将其插入到新索引处
          const currRow = this.tableData.splice(evt.oldIndex, 1)[0];
          this.tableData.splice(evt.newIndex, 0, currRow);
          moveRule(currRow.id, {
            prev: evt.newIndex === 0 ? undefined : this.tableData[evt.newIndex - 1].id,
            next:
              evt.newIndex === this.tableData.length - 1
                ? undefined
                : this.tableData[evt.newIndex + 1].id,
          }).catch(() => {
            this.fetchData().then(() => {
              this.initSort();
            });
          });
        },
      });
    },
  },
};
</script>

<style>
.dragged-icon {
  cursor: pointer;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
</style>
